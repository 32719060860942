import React from 'react'
import { RichText } from 'prismic-reactjs'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { cover, rgba } from 'polished'

import {
  Box,
  DisplayText,
  FlexCol,
  FlexGrid,
  FlexRow,
  H2,
  H6,
  Highlight,
  SEO,
  Span,
  Text,
} from 'src/components'

import serializer from 'src/utils/serializer'
import linkResolver from 'src/utils/linkResolver'

const WhySsubi = () => (
  <StaticQuery
    query={graphql`
      query {
        portrait: file(relativePath: { eq: "philip-ndugga.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 256) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        happyChildren: file(relativePath: { eq: "happy-children.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        why: prismicWhyPage {
          dataString
          data {
            quote_image {
              fluid(maxWidth: 512) {
                ...GatsbyPrismicImageFluid
              }
            }
            how_we_give_hope_image {
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    `}
    render={data => {
      let page = JSON.parse(data.why.dataString)
      return (
        <>
          <SEO
            title={
              page.opengraph_title ? RichText.asText(page.opengraph_title) : ''
            }
            description={
              page.opengraph_description
                ? RichText.asText(page.opengraph_description)
                : ''
            }
            image={page.opengraph_image ? page.opengraph_image.url : ''}
          />
          <Box px={[3, null, null, 4]} py={4} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <FlexCol alignItems="center">
                <DisplayText
                  textAlign="center"
                  style={{ transform: 'rotate(-7.5deg)' }}
                >
                  <Highlight variant={1}>
                    {RichText.asText(page.hero_header)}
                  </Highlight>
                </DisplayText>
                <Box maxWidth={512} mx="auto">
                  <H2
                    children={RichText.asText(page.hero_subheader)}
                    textAlign="center"
                  />
                </Box>
              </FlexCol>
            </Box>
          </Box>
          <Box px={[3, null, null, 4]} pb={[4, 5]} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <Box>
                <FlexGrid gutterX={[3, null, 4, 5]} gutterY={[2]}>
                  <Box width={[1, null, 1 / 2]}>
                    <H2 children={RichText.asText(page.intro_heading)} />
                  </Box>
                  <Box width={[1, null, 1 / 2]}>
                    {RichText.render(page.intro_body, linkResolver, serializer)}
                  </Box>
                </FlexGrid>
              </Box>
            </Box>
          </Box>
          <Box px={[3, null, null, 4]} py={[4, 5]} bg={rgba('#EAAA42', 1 / 3)}>
            <Box maxWidth={704} mx="auto">
              <Box>
                <H2
                  children={RichText.asText(page.how_we_came_to_be_heading)}
                  mb="0.5em"
                  textAlign={['left', 'center']}
                />
                <Span textAlign={['left', 'center']}>
                  {RichText.render(
                    page.how_we_came_to_be_body,
                    linkResolver,
                    serializer
                  )}
                </Span>
              </Box>
            </Box>
          </Box>
          <Box px={[3, null, null, 4]} py={[4, 5]} bg="brand.green">
            <Box maxWidth={1280} mx="auto">
              <Box>
                <FlexGrid
                  justifyContent="center"
                  gutterX={[3, 4]}
                  gutterY={[2]}
                >
                  <FlexRow justifyContent="center" width={[1, 'initial']}>
                    <Box width={[128, 160, 192]}>
                      <Box
                        position="relative"
                        pb="100%"
                        bg="bg.alt"
                        borderRadius={9999}
                        style={{ overflow: 'hidden' }}
                      >
                        <Img
                          fluid={data.why.data.quote_image.fluid}
                          style={{
                            ...cover(),
                            width: '100%',
                            height: '100%',
                            userSelect: 'none',
                          }}
                        />
                      </Box>
                    </Box>
                  </FlexRow>
                  <Box flex={['initial', 1]} width={[1, 'initial']}>
                    <Text
                      mb="1em"
                      fontSize={2}
                      fontWeight={700}
                      textAlign={['center', 'initial']}
                      color="text.reverse"
                      style={{
                        hangingPunctuation: 'first',
                      }}
                    >
                      &#8220;{RichText.asText(page.quote)}&#8221;
                    </Text>
                    <H6
                      children={page.quote_author}
                      textAlign={['center', 'initial']}
                      color="text.reverse"
                    />
                  </Box>
                </FlexGrid>
              </Box>
            </Box>
          </Box>
          <Box px={[3, null, null, 4]} py={[4, 5]} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <Box>
                <FlexGrid
                  justifyContent="center"
                  alignItems="center"
                  gutterX={[2, 3, 4]}
                  gutterY={[3]}
                >
                  <Box width={[1, 2 / 5]}>
                    <Img fluid={data.why.data.how_we_give_hope_image.fluid} />
                  </Box>
                  <Box width={[1, 3 / 5]}>
                    <Box>
                      <H2
                        children={RichText.asText(
                          page.how_we_give_hope_heading
                        )}
                        mb="0.5em"
                      />
                      {RichText.render(
                        page.how_we_give_hope_body,
                        linkResolver,
                        serializer
                      )}
                    </Box>
                  </Box>
                </FlexGrid>
              </Box>
            </Box>
          </Box>
        </>
      )
    }}
  />
)

export default WhySsubi
